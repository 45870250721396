import * as React from "react";
import { graphql } from "gatsby";
import { Constants } from "../@types/Constants";
import IParagraph from "../@types/IParagraph";
import Layout from "../components/global/Layout/Layout";
import { IOurBrandsProps } from "../pageHelpers/OurBrands/OurBrandsProps";
import ParagraphLayout from "../components/common/ParagraphLayout/ParagraphLayout";
import { getParagraph } from "../utils/paragraphHelpers";
import SEO from "../components/global/SEO/SEO";
import { addPageTypeInGTMDataLayer } from "../utils/datalayers";
//import { addKoddiPageTag } from "../utils/KoddiTagsGTM";

const OurBrands = (props: IOurBrandsProps) => {
  const _page = props.data.page;
  const _paragraphs = _page.relationships.paragraphs.map(getParagraph);
  const _pageBanner = _paragraphs.filter((p:IParagraph) => { return p ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER : false; });

  React.useEffect(() => {
    try {
      addPageTypeInGTMDataLayer('brand page');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);
  
    return (
      <Layout>
        <SEO 
          title={_page?.title} 
          description={_page?.field_meta_description} 
          koddiTitle="Our Brands"
          />
        {_pageBanner}
        <ParagraphLayout {..._paragraphs} />
      </Layout>
    );
};
export const pageQuery = graphql`
  query OurBrandsQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    page: nodePage(path: {alias: {eq: "/v1/our-brands"}}) {
      id
      title
      field_meta_description
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphPageBanner
          ...ParagraphPageIntro
          ...ParagraphOurBrandsTeasers
          ...ParagraphMediaCta
        }
      }
    }
  }
`;
export default OurBrands;
